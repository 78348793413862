

.App {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.App-privacy {
    text-align: center;
    background-color: #ff6961;

}

.App-logo {
  height: 50vmin;
  pointer-events: none;
  margin-right: 120px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    
  }
}


.App-body {
  display: grid;
  background-color: #ff6961;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Palatino;
  grid-column-start:2;
  margin: 0
}

.App-privacy-body {
   background-color: #ff6961;
   text-align: left; 

}

.App-body h1 {
  margin: 0
}

.App-contact {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #ff6961;
  align-items: center;
  justify-content: start;
  color: black;
  font-family: Palatino;
  grid-column-start:1
}

.App-contact h1 {
  margin: 0
}


.App-contact-body {
  display: grid;
  background-color: #ff6961;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Palatino;
  grid-column-start:2;
  margin: 0;
  text-align: center
}


.App-about {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #ff6961;
  align-items: center;
  justify-content: start;
  color: black;
  font-family: Palatino;
  grid-column-start:2;
  grid-column-end:4
}

.App-about h1 {
  margin: 0
}



.App-about-body {
  display: grid;
  background-color: #ff6961;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Palatino;
  grid-column-start:2;
  grid-column-end:4;
  margin: 0;
  text-align: center
}



.App-nav {
  background-color: #ff6961;
  align-items: flex-end;
  justify-content: start;
  color: black;
  font-family: Palatino;
  grid-column-start:3;
  margin:0

}




.App-header {
  background-color: #ff6961;
  flex-direction: column;
  align-items: right;
  justify-content: column;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Palatino;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-section {
  font-size: 40px

}


.social-container {
  background: #ff6961;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.facebook {
  color: #4267B2;
}

a.instagram {
  color:#8a3ab9;
}


.pobbleblog {
    font-family: Tahoma, sans-serif;
    margin: 25px 50px 75px 100px;

}

body {
background-color: #72AA27,

}



