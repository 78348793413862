pre {
  white-space: pre-wrap
}

html {
  padding: 3%
}


.centertext {
  text-align: center;
  justify-content: center

}


